<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12" sm="6">
          <h5>{{ $t("user.contact") }}</h5>
        </b-col>

        <b-col cols="12" sm="6"> </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row, error: v$.user.givenName.$errors.length }"
            :label="$t('user.givenName')"
            label-for="h-first-name"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="user.givenName" id="h-first-name" />
            <div
              class="input-errors"
              v-for="error of v$.user.givenName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row, error: v$.user.familyName.$errors.length }"
            :label="$t('user.familyName')"
            label-for="h-last-name"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="user.familyName" id="h-last-name" />
            <div
              class="input-errors"
              v-for="error of v$.user.familyName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('user.telephone')"
            label-for="h-phone"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-phone" v-model="user.telephone" />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row, error: v$.user.email.$errors.length }"
            :label="$t('forms.email')"
            label-for="h-email"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-email" type="email" v-model="user.email" @keydown.space.prevent />

            <div
              class="input-errors"
              v-for="error of v$.user.email.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            class="row"
            :label="$t('user.description')"
            label-for="h-comment"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input
              id="h-comment"
              name="Comment"
              v-model="user.description"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <h5>{{ $t("user.address") }}</h5>
        </b-col>

        <b-col cols="12" sm="6"> </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.streetAddress')"
            label-for="h-streetAddress"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input
              id="h-streetAddress"
              v-model="user.address.streetAddress"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.postalCode')"
            label-for="h-postalCode"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-postalCode" v-model="user.address.postalCode" />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.city')"
            label-for="h-city"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-city" v-model="user.address.city" />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            class="row"
            :label="$t('address.country')"
            label-for="h-country"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input
              id="h-country"
              v-model="user.address.country"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <h5>{{ $t("address.company") }}</h5>
        </b-col>

        <b-col cols="12" sm="6"> </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.companyName')"
            label-for="h-companyName"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input
              id="h-companyName"
              v-model="user.companyName"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.ico')"
            label-for="h-ico"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input
              id="h-ico"
              v-model="user.ico"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.dic')"
            label-for="h-dic"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-dic" v-model="user.dic" />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row }"
            :label="$t('address.icdph')"
            label-for="h-icdph"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input id="h-icdph" v-model="user.address.icdph" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {},
  props: {
    userObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      user: {
        givenName: { required: this.$translateError('required') },
        familyName: { required: this.$translateError('required') },
        email: { required: this.$translateError('required'), email: this.$translateError('email') },
      },
    };
  },
  data() {
    return {
      user: this.userObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Users,
                this.user,
                this.$t("customer.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Users,
                this.user.id,
                this.user,
                this.$t("customer.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/customers`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>
